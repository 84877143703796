var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": _vm.maxWidth },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-4" },
        [
          _vm.title
            ? _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(_vm._s(_vm.title))
              ])
            : _vm._e(),
          _vm.text !== null
            ? _c(
                "v-card-text",
                {
                  staticClass: "font-weight-black text-center pl-0 pr-0 mx-auto"
                },
                [_vm._v("\n      " + _vm._s(_vm.text) + "\n    ")]
              )
            : _vm._e(),
          _vm._l(_vm.splitMessageLine(_vm.multiLineText), function(
            text,
            index
          ) {
            return _c(
              "v-card-text",
              {
                key: index,
                class: _vm.isLineBreakStyle(text),
                attrs: { align: "left" }
              },
              [_vm._v("\n      " + _vm._s(text) + "\n    ")]
            )
          }),
          _c(
            "v-card-text",
            [
              _c("MultiLineBoldText", {
                attrs: { value: _vm.multiLineBoldText }
              })
            ],
            1
          ),
          _vm.subText !== null
            ? _c(
                "v-card-text",
                { staticClass: "font-weight-black text-center" },
                [_vm._v(_vm._s(_vm.subText))]
              )
            : _vm._e(),
          _c(
            "v-card-actions",
            { staticClass: "justify-center" },
            [
              _vm.negativeButtonTitle !== null
                ? [
                    _c(
                      "v-btn",
                      {
                        staticClass: "title font-weight-black mr-4",
                        staticStyle: { "font-size": "15px !important" },
                        attrs: {
                          rounded: "",
                          color: "back",
                          width: "30%",
                          "max-width": "170px",
                          dark: ""
                        },
                        on: { click: _vm.onClickNegativeButton }
                      },
                      [_vm._v(_vm._s(_vm.negativeButtonTitle))]
                    )
                  ]
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "title font-weight-black",
                  staticStyle: { "font-size": "15px !important" },
                  attrs: {
                    disabled: _vm.disabled,
                    rounded: "",
                    color: "next",
                    width: "30%",
                    "max-width": "170px",
                    dark: ""
                  },
                  on: { click: _vm.onClickPositiveButton }
                },
                [_vm._v(_vm._s(_vm.positiveButtonTitle))]
              )
            ],
            2
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }